import { useQuery} from '@apollo/client';
import { Queries } from "./common-queries";

export const PropertyDetails = (id) => {
    return useQuery(Queries.getPropertyDetails, {
        variables: {
            id: id
        }
    })
}

export const GetPropertiesList = (limit) => {
    return useQuery(Queries.getPropertyList, {
        variables: {
            Limit: limit
        }
    })
}

export const GetPropertiesListByTeam = (limit) => {
    return useQuery(Queries.getPropertyList, {
        variables: {
            Limit: limit
        }
    })
}
export const GetPropertiesListByStatus = (area, status) => {
    return useQuery(Queries.getPropertyListByStatus, {
        variables: {
            Prop_Area: area,
            Prop_Status: status
        }
    })
}

export const AreaGuideDetails = (area) => {
    return useQuery(Queries.getLocalArea, {
        variables: {
            Prop_Area: area,
            // Prop_Address1: address1,
            // Prop_Address2: address2,
            // Prop_Address3: address3
        }
    })
}

export const SoldProperties = (status) => {
    return useQuery(Queries.getSoldPropertiess, {
        variables: {
            Status: status
        }
    })
}

export const PropertiesFromMovies = (areas) => {
    return useQuery(Queries.getPropertyListByAreas)
}

// export const PropertiesFromMovies = (areas) => {
//     return useQuery(Queries.getPropertyListByAreas, {
//         variables: {
//             Areas: areas
//         }
//     })
// }

export const AreaGuidesByPostCodes = (postcodes) => {
    return useQuery(Queries.getAreaGuideByPostCodes, {
        variables: {
            Prop_Postcodes: postcodes
        }
    })
}

export const TeamMemberDetail = (ids) => {
    return useQuery(Queries.getTeamDetailByCRMId, {
        variables: {
            Prop_Ids: ids
        }
    })
}

export const OfficeDetail = (ids) => {
    console.log("ids:- ",ids)
    return useQuery(Queries.getOfficeDetailById, {
        variables: {
            Office_ID: ids
        }
    })
}

export const TeamMemberList = () => {
    return useQuery(Queries.getTeamList)
}

export const PropertyListByTeam = (Email, limit) => {
    return useQuery(Queries.getPropertyListByTeam, {
        variables: {
            Prop_Email: Email,
            Limit: limit,
            Prop_Status: "Sold"
        }
    })
}
