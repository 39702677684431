import gql from "graphql-tag";

const getPropertyDetails = gql`
    query PropertyDetails($id: ID!) {
        property (id:$id) {
            id
            area
            bathroom
            bedroom
            building
            brochure
            createdAt
            display_address
            description
            department
            extra
            floor_level
            floorarea_max
            floorarea_min
            floorplan
            floorarea_type
            images
            max_bedrooms
            parking
            parking_spaces
            reception
            rent
            room_details
            search_type
            situation
            slug
            special
            status
            style
            title
            updatedAt
            virtual_tour
            price
            price_qualifier
            primary_development
            latitude
            longitude
            long_description
            imagetransforms
            address
            accomadation_summary
            archive_after
            available_from
            crm_provider
            crm_negotiator_id
            crm_id
            crm_area_id
            introtext
            office_crm_id
            negotiatorID
            publish
        }
    }`

const getLocalArea = gql`
    query AreaDetails ($Prop_Area: String!) {
        areaGuides (where: { Title_contains: $Prop_Area }) {
            URL
            Title
            Embed_Video_URL
            _id
            local_living {
                URL
                id
            }
            Tile_Image {
                url
                alternativeText
            }
            Content {
                ... on ComponentModulesContent {
                  id
                  Add_Static_Content
                }
            }
            Post_Code
            Intro_Content
            Banner_Title
            imagetransforms
        }
    }`
;

// const getLocalArea = gql`
//     query AreaDetails ($Prop_Postcode: String!, $Prop_Address1: String!, $Prop_Address2: String!, $Prop_Address3: String! ) {
//         areaGuides (where:{ _or: [{ Post_Code_contains: $Prop_Postcode }, {Title_contains: $Prop_Address1}, {Title_contains: $Prop_Address2}, {Title_contains: $Prop_Address3},  ]}) {
//             URL
//             Title
//             Embed_Video_URL
//             _id
//             Tile_Image {
//                 url
//                 alternativeText
//             }
//             Content {
//                 ... on ComponentModulesContent {
//                   id
//                   Add_Static_Content
//                 }
//             }
//             Post_Code
//             Intro_Content
//             Banner_Title
//             imagetransforms
//         }
//     }`
// ;

const getAreaGuideByPostCodes = gql`
    query AreaGuides($Prop_Postcodes: [String!]!){
        areaGuides(where: {Post_Code_contains: $Prop_Postcodes}) {
            _id
            Title
            Post_Code
        }
    }
`

const getTeamDetailByCRMId = gql`
    query Teams($Prop_Ids: [String!]!){
        teams(where: {Calendly_Link_contains: $Prop_Ids }) {
            Email
            Name
            Phone
            Tile_Image {
                url
            }
            imagetransforms
        }
    }
`

const getSoldPropertiess = gql`
    query SoldProperties($Status: String!){
        properties(where: {status: $Status, publish: true }, sort: "time_amended:desc") {
            _id
            area
            images
        }
    }
`

const getPropertyListByAreas = gql`
    query PropertyList {
        properties(where: {publish: true, virtual_tour_contains: null}, sort: "time_amended:desc") {
            _id
            area
            price_qualifier
            display_address
            price
            images
            title
            virtual_tour
            imagetransforms
        }
    }
`

const getPropertyList = gql`
    query PropertyList($Limit: Int!){
        properties(limit:$Limit, sort: "time_amended:desc", where: {publish: true}) {
            _id
            price_qualifier
            address
            display_address
            search_type
            slug
            price
            images
            id
            description
            status
            title
            available_from
            imagetransforms
        }
    }
`

const getTeamDetailByMail = gql`
    query Teams($Prop_Email: String!){
        teams(where: {Email_contains: $Prop_Email }) {
            Email
            Tile_Image {
                url
            }
            imagetransforms
        }
    }
`

const getTeamList = gql`
    query TeamsList {
        teams(sort: "Review_Sort", where: {Use_Img_For_Review: true }) {
            _id
            Email
            Tile_Image {
                url,
                alternativeText
            }
            imagetransforms
        }
    }
`

const getPropertyListByStatus = gql`
    query PropertyList($Prop_Area: String!, $Prop_Status: String!){
        properties(where: { area_contains: $Prop_Area, status_contains: $Prop_Status, publish: true }, sort: "time_amended:desc") {
            _id
            price_qualifier
            area
            address
            display_address
            search_type
            slug
            price
            images
            id
            description
            status
            title
            available_from
            imagetransforms
        }
    }
`

const getPropertyListByTeam = gql`
    query PropertyList($Limit: Int!, $Prop_Email: String!, $Prop_Status: String!){
        properties(limit:$Limit, where: { _or: [{crm_negotiator_id: $Prop_Email }, {negotiatorID: $Prop_Email }], status_contains: $Prop_Status, publish: true }, sort: "time_amended:desc") {
            _id
            price_qualifier
            address
            display_address
            search_type
            slug
            price
            images
            id
            description
            status
            title
            available_from
            imagetransforms
        }
    }
`

const getOfficeDetailById = gql`
  query OfficeDetail($Office_ID: String!){
      offices(where: {Office_crm_id_contains: $Office_ID }) {
        Title
        Sub_Title
        Address
        Mobile
        Email
        id
        Image {
          url
        }
        URL
      }
  }
`

export const Queries = {
    getPropertyDetails,
    getLocalArea,
    getAreaGuideByPostCodes,
    getSoldPropertiess,
    getPropertyList,
    getPropertyListByAreas,
    getPropertyListByTeam,
    getPropertyListByStatus,
    getTeamDetailByMail,
    getTeamDetailByCRMId,
    getTeamList,
    getOfficeDetailById
}
